import './styles/Posts.css';
import React, { useState  } from 'react';
import { gql, useApolloClient, useQuery } from "@apollo/client";
import { useAuthContext } from "../context/AuthContext";
import { useParams } from "react-router-dom";
import { Socket } from "socket.io-client";

type SinglePost = {
	text: string;
	date: string;
	likes: string;
	picture: string;
	id: string;
};

type SingleComment = {
	user: string;
	comment: string;
	time: string;
	id: string;
	post: string;
};

type Comments = {
	[key: string]: SingleComment[];
};

const GET_POSTS = gql`
	query GetPosts($username: String!) {
		user(username: $username) {
			posts {
				id
				content
				date
                likes
				picture
				comments {
					id
					content
					date
					username
					post
				}
			}
		}
	}
`;

const MAKE_COMMENT = gql`
	mutation CreatePost(
		$username: String!
		$token: String!
		$post: ID!
		$content: String!
		$date: DateTime!
	) {
		createComment(
			username: $username
			token: $token
			post: $post
			content: $content
			date: $date
		) {
			id
		}
	}
`;

interface PostsProps {
    socket: Socket;
}

const Posts: React.FC<PostsProps> = (props) => {
	const socket = props.socket;
    const { streamer: username } = useParams();
    const { username: loggedInUsername, jwt: token } = useAuthContext();
	const [posts, setPosts] = useState<SinglePost[]>([]);
	const [comments, setComments] = useState<Comments>({});
	const apolloClient = useApolloClient();

	const {data} = useQuery(GET_POSTS, {
		variables: { username },
		pollInterval: 4000,
		onCompleted:(data) => {
			const posts = [] as SinglePost[];
				if (data.user) {
					const userPosts = data.user.posts;
					for (let i = 0; i < userPosts.length; i++) {
						const post = userPosts[i];
						posts.push({
							text: post.content,
							date: post.date,
							id: post.id,
							picture: post.picture,
							likes: post.likes,
						});

						const postComments = post.comments;
						comments[post.id] = [];

						for (let j = 0; j < postComments.length; j++) {
							const currentComment = postComments[j];
							comments[post.id].push({
								user: currentComment.username,
								comment: currentComment.content,
								time: currentComment.date,
								id: currentComment.id,
								post: post.id,
							});
						}
					}
				}

				setComments(comments);
				setPosts(posts);
		}
	});
	if (data) {}

	const convertDateToText = (date: string) => {
		return new Date(date).toLocaleString();
	};

	const handleNotification = () => {
		socket.emit("sendNotification", {
		  senderName: loggedInUsername,
		  receiverName: username,
		  message: loggedInUsername + " commented on your post!",
		  date: new Date().toLocaleString(),
		});
	  };

	const HandleMakeComment = (postId: string, commentText: string) => {
		if (commentText.length > 0) {
			let commentDate = new Date().toISOString();
			apolloClient
				.mutate({
					mutation: MAKE_COMMENT,
					variables: {
						username: loggedInUsername,
						token: token,
						post: postId,
						content: commentText,
						date: commentDate,
					},
				})
				.then(() => {
					setComments({
						...comments,
						[postId]: [
							{
								user: loggedInUsername,
								comment: commentText,
								time: commentDate,
								id: "",
								post: postId,
							},
							...comments[postId],
						],
					});
					handleNotification();
				});
		}
	};

    return (
        <div className="PersonalPosts">
            {posts.length > 0
                ? <div className="Posts">
                    {posts.map((post, index) => (
                        <div className="Post" key={index}>
                            <div className="PostHeader">
                                <span className="PostUser">{username}</span>
                                <span className="PostDate">{convertDateToText(post.date)}</span>
                            </div>
							{ post.picture && (
								<div className="Image">
									<img
										src={process.env.REACT_APP_REST_URL + "image/" + post.picture}
					        			alt={username}
									/>
								</div>
							)}
                            <div className="Text">
                                <p>{post.text}</p>
                            </div>
                            {/* <div className="Likes">
                                <p>Likes: {post.likes}</p>
                            </div> */}
                            <div className="MakeComment">
                                <textarea
                                    placeholder="Write a comment..."
                                    name="makeComment"
                                    id="makeComment"
                                    onKeyPress={(event) => {
                                        if (event.key === 'Enter') {
											event.preventDefault();
											HandleMakeComment(post.id, event.currentTarget.value);
											event.currentTarget.value = "";
                                        }
                                    }}
                                />
                            </div>
							{comments[post.id] && (
								<div className="Comments" key={index}>
									{comments[post.id].map((comment, commentIndex) => (
										<div className="Comment" key={commentIndex}>
											<div className="CommentHeader">
												<span className="CommentUser">{comment.user}</span>
												<span className="CommentDate">
													{convertDateToText(comment.time)}
												</span>
											</div>
											<p className="CommentText">{comment.comment}</p>
										</div>
									))}
								</div>
							)}
                        </div>
                    ))}
                </div>
                : <div className="NoPost">
                    <p>{username} hasn't posted anything yet..</p>
                </div>
            }
        </div>
    );
}

export default Posts;