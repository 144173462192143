import React, { useEffect, useState } from 'react';
import { gql, useApolloClient } from "@apollo/client";
import { useParams, Link } from "react-router-dom";
import './styles/ProfileSearchResults.css'

const FIND_PROFILES = gql`
	query MatchUsername($name: String!) {
		matchUsername(name: $name) {
			username
            picture
            followerCount
		}
	}
`;

const ProfileSearchResults = () => {
    const { profile: name } = useParams();
    const apolloClient = useApolloClient();
    const [foundProfiles, setFoundProfiles] = useState<Array<{username: string, picture: string, followerCount: number}>>([]);

    useEffect(() => {
		apolloClient
			.query({
				query: FIND_PROFILES,
				variables: { name },
			})
			.then((res) => {
				setFoundProfiles(res.data.matchUsername);
			});
	});

    return (
        <div className="ProfileSearchResults">
           <div className="FoundProfilesList">
                {foundProfiles.length > 0
                    ? <div className="ProfilesFound">
                        <h1>Is this what you're looking for?</h1>
                        <div className="ProfilesFoundContainer">
                            {foundProfiles.map((profile, index) => (
                                <div className="CurrentProfile" key={index}>
                                    <Link style={{ textDecoration: 'none', color: 'white' }} to={"/profile/" + profile.username}>
                                        <img
                                            src={process.env.REACT_APP_REST_URL + "image/" + profile.picture}
                                            alt={profile.username}
                                        ></img>
                                        <p>{profile.username}</p>
                                        <p id="CurrentProfileFollowerCount">{profile.followerCount} followers</p>
                                    </Link>
                                </div>
                            ))}
                        </div>
                    </div>
                    : <div className="NoProfilesFound">
                        <h1>We couldn't find anyone...</h1>
                        <p>Go back <span id="LinkToHome"><Link style={{ textDecoration: 'none', color: '#82deff' }} to="/">home</Link></span>?</p>
                    </div>
                }
            </div>
        </div>
    );
} 

export default ProfileSearchResults;