import { gql, useApolloClient } from "@apollo/client";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router";
import * as mediasoupClient from "mediasoup-client";
import { io, Socket } from "socket.io-client";
import { useAuthContext } from "../context/AuthContext";
import "./styles/PersonalLiveStream.css";

type ChatMessage = {
	clientId: string;
	message: string;
	from: string;
	date: Date;
};

const WatchStream = () => {
	const videoRef = useRef<HTMLVideoElement>(null);
	const { id } = useParams();

	const MEDIA_SERVER_URL =
		process.env.REACT_APP_MEDIA_SERVER_URL || "localhost:4002";

	const { jwt, username } = useAuthContext();
	const [title, setTitle] = useState("");
	const [streamBy, setStreamBy] = useState("");
	const [publishedOn, setPublishedOn] = useState<Date>();
	const [status, setStatus] = useState("");
	const [message, setMessage] = useState("");
	const [currentClientId, setCurrentClientId] = useState("");
	const [chatMessages, setChatMessages] = useState<ChatMessage[]>([]);
	const [currentSocket, setCurrentSocket] = useState<Socket>();
	const [videoSrc, setVideoSrc] = useState("");
	const [tracks, setTracks] = useState<MediaStreamTrack[]>([]);
	const navigate = useNavigate();

	const consoleLog = (...args: any) => {
		console.log(...args);
	};

	const updateTracks = (tracks: MediaStreamTrack[]) => {
		if (videoRef.current) {
			const stream = new MediaStream();
			tracks.forEach((track) => {
				stream.addTrack(track);
			});
			videoRef.current.srcObject = stream;
		}
		setTracks(tracks);
	};

	const addTrack = (track: MediaStreamTrack) => {
		if (videoRef.current) {
			const stream = videoRef.current.srcObject as MediaStream;
			stream.addTrack(track);
		}
		setTracks((tracks) => [...tracks, track]);
	};

	const apolloClient = useApolloClient();

	const GET_LIVE_STREAM = gql`
		query GetLiveStream($id: String!, $token: String!, $username: String!) {
			livestreamById(id: $id, token: $token, username: $username) {
				title
				username
				status
				date
			}
		}
	`;

	const sendWatchNotification = (target: string) => {
		/*notificationSocket.emit("sendNotification", {
			senderName: username,
			receiverName: target,
			message: username + " started watching your stream!",
			date: new Date().toLocaleString(),
		});*/
	};

	useEffect(() => {
		const effectFcn = async () => {
			consoleLog("Connecting...");

			const socket = io("wss://" + MEDIA_SERVER_URL);
			setCurrentSocket(socket);

			const roomId = id;

			consoleLog("Room ID:", id);

			socket.on(
				"receive_message",
				({ clientId, message, timestamp, from }: any) => {
					consoleLog(`[${clientId}] ${message}`);
					setChatMessages((messages) => [
						{
							clientId,
							message,
							date: new Date(timestamp),
							from,
						},
						...messages,
					]);
				}
			);

			socket.on("connect", () => {
				consoleLog("Connected!");

				// Join the room.
				socket.emit(
					"join_room",
					{ roomId: id, username },
					(err: any, clientId: any) => {
						if (err) {
							console.error(err);
							return;
						}

						consoleLog("Joined room:", clientId);
						setCurrentClientId(clientId);

						socket.emit(
							"get_capabilities",
							{ roomId: id },
							async (err: any, capabilities: any) => {
								if (err) {
									console.error(err);
									return;
								}

								const device = new mediasoupClient.Device();

								await device.load({
									routerRtpCapabilities: capabilities,
								});

								// Create transport
								socket.emit(
									"create_transport",
									{ roomId: id, clientId },
									(err: any, transportParams: any) => {
										if (err) {
											console.error(err);
											return;
										}

										const {
											id,
											iceParameters,
											iceCandidates,
											dtlsParameters,
											sctpParameters,
										} = transportParams;

										const recv_transport = device.createRecvTransport({
											id,
											iceParameters,
											iceCandidates,
											dtlsParameters,
											sctpParameters,
											iceServers: [
												{
													urls: "stun:stun.l.google.com:19302",
												},
												{
													urls: "stun:stun1.l.google.com:19302",
												},
											],
										});

										consoleLog("Transport created: ", recv_transport);

										recv_transport.on(
											"connect",
											({ dtlsParameters }, callback, errback) => {
												// Call server to consume the stream.
												socket.emit(
													"connect_transport",
													{
														roomId,
														clientId,
														transportId: id,
														dtlsParameters,
													},
													(err: any) => {
														if (err) {
															console.error(err);
															errback(err);
															return;
														}
														consoleLog("Transport connected");
														callback();
													}
												);
											}
										);

										socket.emit(
											"consume",
											{
												roomId,
												clientId,
												transportId: id,
												rtpCapabilities: device.rtpCapabilities,
												type: "video",
											},
											async (err: any, consumerParams: any) => {
												if (err) {
													console.error(err);
													return;
												}
												consoleLog("Consuming video track: ", consumerParams);

												const { id, kind, producerId, rtpParameters } =
													consumerParams;

												const consumer = await recv_transport.consume({
													id,
													kind,
													producerId,
													rtpParameters,
												});

												const track = consumer.track;
												updateTracks([track, ...tracks]);

												consoleLog("Stream added to video element");
											}
										);

										socket.emit(
											"consume",
											{
												roomId,
												clientId,
												transportId: id,
												rtpCapabilities: device.rtpCapabilities,
												type: "audio",
											},
											async (err: any, consumerParams: any) => {
												if (err) {
													console.error(err);
													return;
												}
												consoleLog("Consuming audio track: ", consumerParams);

												const { id, kind, producerId, rtpParameters } =
													consumerParams;

												const consumer = await recv_transport.consume({
													id,
													kind,
													producerId,
													rtpParameters,
												});

												const track = consumer.track;
												addTrack(track);

												consoleLog("Audio stream added to element");
											}
										);
									}
								);
							}
						);
					}
				);
			});

			socket.connect();
		};

		apolloClient
			.query({
				query: GET_LIVE_STREAM,
				variables: {
					id: id,
					token: jwt,
					username: username,
				},
			})
			.then((res) => {
				if (res.data.livestreamById === null) {
					navigate("/me");
					return;
				}

				setTitle(res.data.livestreamById.title);
				setStreamBy(res.data.livestreamById.username);
				setStatus(res.data.livestreamById.status);
				setPublishedOn(new Date(res.data.livestreamById.date));
				sendWatchNotification(res.data.livestreamById.username);

				if (res.data.livestreamById.status === "ended") {
					// Set video src
					console.log("https://" + MEDIA_SERVER_URL + "/vod/" + id);
					setVideoSrc("https://" + MEDIA_SERVER_URL + "/vod/" + id);
				} else {
					effectFcn();
				}
			})
			.catch((err) => {
				console.error(err);
			});

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (!id) {
		navigate("/me");
		return <div>No stream id provided</div>;
	}

	return (
		<div className="PersonalLiveStream">
			<div className="PersonalLiveStream__container">
				<div className="PersonalLiveStream__container__video">
					<video
						className="PersonalLiveStream__container__video__video"
						autoPlay
						muted
						loop
						controls
						ref={videoRef}
						src={videoSrc}
					/>
				</div>
				<div className="PersonalLiveStream__container__description">
					<div className="PersonalLiveStream__container__description__title">
						<h3 className="PersonalLiveStream__container__description__header">
							{title || "(Untitled)"}
						</h3>
						<h3
							className="PersonalLiveStream__container__description__header"
							style={{
								cursor: "pointer",
								textDecoration: "underline",
							}}
							onClick={() => {
								navigate("/profile/" + streamBy);
							}}
						>
							By {streamBy}
						</h3>
					</div>
					<p className="PersonalLiveStream__container__description__streamid">
						Stream ID {id}
						<span className="PersonalLiveStream__container__description__streamid__status">
							{status}
						</span>
					</p>
					<p className="PersonalLiveStream__container__description__streamid">
						Originally published on {publishedOn && publishedOn.toLocaleString()}
					</p>
				</div>
				{status !== "ended" && (
					<>
						<div className="PersonalLiveStream__container__description">
							<h3 className="PersonalLiveStream__container__description__header">
								Stream chat
							</h3>
							<p className="PersonalLiveStream__container__description__subheader">
								Message
							</p>
							<textarea
								className="PersonalLiveStream__container__actions__textarea"
								placeholder="Type your message here!"
								onChange={(e) => setMessage(e.target.value)}
								value={message}
								onKeyDown={(e) => {
									if (e.key === "Enter") {
										e.preventDefault();
										if (currentSocket) {
											currentSocket.emit("send_message", {
												roomId: id,
												clientId: currentClientId,
												message: message,
											});
										}
										setMessage("");
									}
								}}
							/>
						</div>
						{chatMessages.length > 0 &&
							chatMessages.map((message) => {
								const clientName =
									message.clientId === currentClientId ? "You" : message.from;
								return (
									<div className="PersonalLiveStream__container__description">
										<p>
											{message.date.toLocaleTimeString()} &nbsp;&nbsp;{" "}
											{clientName} said: {message.message}
										</p>
									</div>
								);
							})}
					</>
				)}
			</div>
		</div>
	);
};

export default WatchStream;
