import "./styles/ForgetPassword.css";
import React, { useState } from "react";
import { gql, useMutation } from "@apollo/client";
import { useParams, Link } from "react-router-dom";

const RESET_PASSWORD = gql`
mutation RecoverPassword($token: String!, $password: String!) {
	recoverPassword(token: $token, password: $password)
}
`;

const ForgetPassword = () => {
	const { forgetPasswordToken } = useParams();
	const [newPassword1, setNewPassword1] = useState("");
	const [newPassword2, setNewPassword2] = useState("");
	const [nonMatchingPasswords, setNonMatchingPasswords] = useState(false);
	const [showLoadingAnimation, setShowLoadingAnimation] = useState(false);
	const [emptyFieldsMessage, setEmptyFieldsMessage] = useState(false);
	const [poorPasswordMessage, setPoorPasswordMessage] = useState(false);
	const [malformedToken, setMalformedToken] = useState(false);
	const [userDNE, setUserDNE] = useState(false);
	const [networkError, setNetworkError] = useState(false);
	const [passwordResetSuccess, setPasswordResetSuccess] = useState(false);

	const [resetPassowrd] = useMutation(RESET_PASSWORD, {
        variables: {
            token: forgetPasswordToken,
            password: newPassword1,
        },
        onCompleted: (data) => {
			setPasswordResetSuccess(true);
			setShowLoadingAnimation(false);
        },
        onError: ({ graphQLErrors, networkError }) => {
			console.log(graphQLErrors[0].message);
            setShowLoadingAnimation(false);
			if (graphQLErrors) {
                switch (graphQLErrors[0].message) {
                    case 'jwt malformed': { 
                        setMalformedToken(true); 
                        break; 
                     }
                     case 'User not found': { 
                        setUserDNE(true); 
                        break; 
                     }
					 case 'invalid token': { 
                        setMalformedToken(true); 
                        break; 
                     }
					 case 'Token not valid for this function': { 
                        setMalformedToken(true); 
                        break; 
                     }
                     default: { 
                        setNetworkError(true);
                        break; 
                     } 
                }
            }

			if (networkError) setNetworkError(true);
		},
    });

	const passwordRequirementCheck = () => {
        if (newPassword1.length < 8) return false;
        if (newPassword1.toUpperCase() === newPassword1) return false;
        if (newPassword1.toLowerCase() === newPassword1) return false;
        return true;
    }

	const handleConfirmNewPassword = () => {
		setNonMatchingPasswords(false);
		setShowLoadingAnimation(false);
		setEmptyFieldsMessage(false);
		setPoorPasswordMessage(false);
		setMalformedToken(false); 
		setUserDNE(false); 
		setNetworkError(false);
		setPasswordResetSuccess(false);

		if (newPassword1.length === 0 || newPassword2.length === 0) {
			setEmptyFieldsMessage(true);
		} else if (newPassword1 !== newPassword2) {
			setNonMatchingPasswords(true);
		} else if (!passwordRequirementCheck()) {
			setPoorPasswordMessage(true);
		} else {
			setShowLoadingAnimation(true);
			resetPassowrd();
		}
	}

	return (
		<div className="ForgetPasswordPage">
			<p>Make a new password</p>

			<div className="NewPassword1">
				<p>Enter your new password</p>
				<input
					placeholder="Password"
					name="new_password_1"
					id="new_password_1"
					value={newPassword1}
					type="password"
					onChange={(e) => setNewPassword1(e.target.value)}
				/>
			</div>

			<div className="NewPassword2">
				<p>Re-enter your new password</p>
				<input
					placeholder="Re-enter password"
					name="new_password_2"
					id="new_password_2"
					value={newPassword2}
					type="password"
					onChange={(e) => setNewPassword2(e.target.value)}
				/>
			</div>

			<p id="newPasswordRequirements">*must be at least 8 characters long<br></br>*must contain at least 1 upper and 1 lowercase letter</p>

			{nonMatchingPasswords && (
				<div className="NonMatchingPasswords">
					<p>The passwords you entered don't match.</p>
				</div>
			)}

			{emptyFieldsMessage && (
				<div className="EmptyFieldsMessage">
					<p>You can't have empty field(s)!</p>
				</div>
			)}

			{poorPasswordMessage && (
				<div className="PoorPasswordMessage">
					<p>Your password doesn't meet the minimum requirements.</p>
				</div>
			)}

			<div className="ConfirmNewPassword">
				<button type="button" onClick={() => handleConfirmNewPassword()}>
					Confirm New Password
				</button>
			</div>

			{showLoadingAnimation && (
				<div className="LoadingAnimation"><div></div><div></div><div></div><div></div></div>
			)}

			{(malformedToken || userDNE) && (
				<div className="MalformedToken">
					<p>This password reset session is invalid.</p>
				</div>
			)}

			{networkError && (
				<div className="NetworkError">
					<p>Oops, something went wrong on our end, try again later.</p>
				</div>
			)}

			{passwordResetSuccess && (
				<div className="PasswordResetSuccess">
					<p>You've successfully reset your password!</p>
					<Link style={{ textDecoration: 'none', color: 'white' }} to="/">
						<div className="BackToLogin">
							<p>go back to login page</p>
						</div>
					</Link>
				</div>
			)}
		</div>
	);
};

export default ForgetPassword;
