import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ApolloClient, ApolloProvider, InMemoryCache } from "@apollo/client";
import AuthProvider from "./providers/AuthProvider";

const client = new ApolloClient({
	uri: process.env.REACT_APP_GRAPHQL_URL || "http://localhost:4000/graphql",
	cache: new InMemoryCache(),
});

console.log(process.env.REACT_APP_GRAPHQL_URL);

ReactDOM.render(
	<BrowserRouter>
		<ApolloProvider client={client}>
			<AuthProvider>
				<App />
			</AuthProvider>
		</ApolloProvider>
	</BrowserRouter>,
	document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
