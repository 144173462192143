import "./styles/PersonalPosts.css";
import { useState } from "react";
import { gql, useApolloClient, useMutation, useQuery } from "@apollo/client";
import { useAuthContext } from "../context/AuthContext";
import axios from "axios";

type SinglePost = {
	text: string;
	date: string;
	likes: string;
	id: string;
	picture: string;
};

type SingleComment = {
	user: string;
	comment: string;
	time: string;
	id: string;
	post: string;
};

type Comments = {
	[key: string]: SingleComment[];
};

const GET_POSTS = gql`
	query GetPosts($username: String!) {
		user(username: $username) {
			posts {
				id
				content
				date
				likes
				picture
				comments {
					id
					content
					date
					username
					post
				}
			}
		}
	}
`;

const MAKE_COMMENT = gql`
	mutation CreatePost(
		$username: String!
		$token: String!
		$post: ID!
		$content: String!
		$date: DateTime!
	) {
		createComment(
			username: $username
			token: $token
			post: $post
			content: $content
			date: $date
		) {
			id
		}
	}
`;

const MAKE_POST = gql`
	mutation CreatePost(
		$username: String!
		$token: String!
		$content: String!
		$picture: String!
		$date: DateTime!
	) {
		createPost(
			username: $username
			token: $token
			content: $content
			picture: $picture
			date: $date
		) {
			id
		}
	}
`;

const DELETE_POST = gql`
	mutation DeletePost(
		$username: String!
		$token: String!
		$post: ID!
	) {
		deletePost(
			username: $username
			token: $token
			post: $post
		)
	}
`;

const PersonalPosts = () => {
	const [postText, setPostText] = useState("");
	const [postImageInitial, setPostImageInitial] = useState<File>();
	const [postImage, setPostImage] = useState<File>();
	const [postImageSrc, setPostImageSrc] = useState("");
	const { username, jwt: token } = useAuthContext();
	const [posts, setPosts] = useState<SinglePost[]>([]);
	const [comments, setComments] = useState<Comments>({});
	const [deletePostConfirmation, setDeletePostConfirmation] = useState<boolean[]>([]);
	const apolloClient = useApolloClient();

	const {data} = useQuery(GET_POSTS, {
		variables: { username },
		pollInterval: 4000,
		onCompleted: (data) => {
			const posts = [] as SinglePost[];
			if (data.user) {
				const userPosts = data.user.posts;
				for (let i = 0; i < userPosts.length; i++) {
					const post = userPosts[i];
					posts.push({
						text: post.content,
						date: post.date,
						id: post.id,
						likes: post.likes,
						picture: post.picture,
					});

					const postComments = post.comments;
					comments[post.id] = [];

					for (let j = 0; j < postComments.length; j++) {
						const currentComment = postComments[j];
						comments[post.id].push({
							user: currentComment.username,
							comment: currentComment.content,
							time: currentComment.date,
							id: currentComment.id,
							post: post.id,
						});
					}
				}
			}

			setComments(comments);
			setPosts(posts);
			setDeletePostConfirmation(new Array(posts.length).fill(false));
		}
	});
	if (data) {}

	const convertDateToText = (date: string) => {
		return new Date(date).toLocaleString();
	};

	const [makePost] = useMutation(MAKE_POST, {
		onCompleted: (data) => {
			setPosts([
				{
					text: postText,
					date: new Date().toISOString(),
					likes: "0",
					id: data.createPost.id,
					picture: postImageSrc,
				},
				...posts,
			]);

			setComments({
				[data.createPost.id]: [],
				...comments,
			})

			setDeletePostConfirmation([
				false,
				...deletePostConfirmation,
			])
		},
		onError: ({ graphQLErrors, networkError }) => {
			if (graphQLErrors)
				graphQLErrors.map(({ message, locations, path }) =>
					console.log(
						`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
					)
				);

			if (networkError) console.log(`[Network error]: ${networkError}`);
		},
	});

	const uploadPostImage = () => {
        if (!postImage) return;

        var formData = new FormData();
        formData.append("image", postImage);

        axios.post(process.env.REACT_APP_REST_URL + "upload/" + username + "/" + token, formData)
            .then(res => {
                setPostImageSrc(res.data.data);
				makePost({variables: {
					username: username,
					token: token,
					content: postText,
					picture: res.data.data,
					date: new Date().toISOString(),
				}});
				setPostText("");
            })
    }

	const HandleMakePost = () => {
		if (postImage) {
			uploadPostImage();
		} else {
			makePost({variables: {
				username: username,
				token: token,
				content: postText,
				picture: "",
				date: new Date().toISOString(),
			}});
			setPostText("");
		}
		setPostImage(postImageInitial);
		setPostImageInitial(postImageInitial);
	};

	const HandleMakeComment = (postId: string, commentText: string) => {
		if (commentText.length > 0) {
			let commentDate = new Date().toISOString();
			apolloClient
				.mutate({
					mutation: MAKE_COMMENT,
					variables: {
						username: username,
						token: token,
						post: postId,
						content: commentText,
						date: commentDate,
					},
				})
				.then(() => {
					setComments({
						...comments,
						[postId]: [
							{
								user: username,
								comment: commentText,
								time: commentDate,
								id: "",
								post: postId,
							},
							...comments[postId],
						],
					});
				});
		}
	};

	const [deletePost] = useMutation(DELETE_POST, {
		onCompleted: (data) => {
		},
		onError: ({ graphQLErrors, networkError }) => {
			if (graphQLErrors)
				graphQLErrors.map(({ message, locations, path }) =>
					console.log(
						`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
					)
				);

			if (networkError) console.log(`[Network error]: ${networkError}`);
		},
	});

	const HandleDeletePost = (i: number) => {
		let temp = [...deletePostConfirmation];
		temp[i] = true;
		setDeletePostConfirmation(temp);
	}

	const HandleCancelDeletePost = (i: number) => {
		let temp = [...deletePostConfirmation];
		temp[i] = false;
		setDeletePostConfirmation(temp);
	}

	const HandleConfirmDeletePost = (postId: string, i: number) => {
		deletePost({variables: {
			username: username,
			token: token,
			post: postId,
		}});

		let temp = [...posts];
		temp.splice(i,1);
		setPosts(temp);

		let temp2 = {...comments};
		delete temp2[postId];
		setComments(temp2);

		let temp3 = [...deletePostConfirmation];
		temp3.splice(i,1);
		setDeletePostConfirmation(temp3);
	}

	return (
		<div className="PersonalPosts">
			<div className="CreatePost">
				<h1>Make a post</h1>
				
				<div className="PostPicture">
                    <input 
                        type="file"
                        id="postPic"
                        className="postPic"
                        accept=".png, .jpg, .jpeg"
                        onChange={(e) => {
                            // @ts-ignore: Object is possibly 'null'.
                            setPostImage(e.target.files[0]);
                        }}
                    />

					{ postImage && (
						<div className="PostPicturePreview">
							<img
								src={URL.createObjectURL(postImage)}
					        	alt="Post"
							/>
						</div>
					)}
                </div>

				<div className="PostText">
					{/* <p>Write something down?</p> */}
					<textarea
						placeholder="What's on your mind?"
						name="postTest"
						id="postTest"
						value={postText}
						onChange={(e) => setPostText(e.target.value)}
					/>
				</div>

				{postText.length > 0 || postImage ? (
					<div className="PostButton">
						<button type="button" onClick={() => HandleMakePost()}>
							Post
						</button>
					</div>
				) : (
					<div className="CannotPostButton">
						<button type="button">Post</button>
					</div>
				)}
			</div>

			{posts && posts.length > 0 ? (
				<div className="Posts">
					{posts.map((post, index) => (
						<div className="Post" key={index}>
							<div className="DeletePost">

								{ deletePostConfirmation[index]
									? <div className="ConfirmDeletePost">
										<p>Are you sure you want to delete this post?</p>
										<button type="button" onClick={() => HandleConfirmDeletePost(post.id, index)}>Confirm</button>
										<button type="button" id="CancelDeletePost" onClick={() => HandleCancelDeletePost(index)}>Cancel</button>
									</div>
									: <button type="button" onClick={() => HandleDeletePost(index)}>Delete Post</button>
								}
							</div>
							<div className="PostHeader">
								<span className="PostUser">{username}</span>
								<span className="PostDate">{convertDateToText(post.date)}</span>
							</div>
							{ post.picture && (
								<div className="Image">
									<img
										src={process.env.REACT_APP_REST_URL + "image/" + post.picture}
					        			alt="post"
									/>
								</div>
							)}
							<div className="Text">
								<p>{post.text}</p>
							</div>
							{/* <div className="Likes">
								<p>Likes: {post.likes}</p>
							</div> */}
							<div className="MakeComment">
								<textarea
									placeholder="Write a comment..."
									name="makeComment"
									id="makeComment"
									onKeyPress={(event) => {
										if (event.key === "Enter") {
											event.preventDefault();
											HandleMakeComment(post.id, event.currentTarget.value);
											event.currentTarget.value = "";
										}
									}}
								/>
							</div>
							{comments[post.id] && (
								<div className="Comments" key={index}>
									{comments[post.id].map((comment, commentIndex) => (
										<div className="Comment" key={commentIndex}>
											<div className="CommentHeader">
												<span className="CommentUser">{comment.user}</span>
												<span className="CommentDate">
													{convertDateToText(comment.time)}
												</span>
											</div>
											<p className="CommentText">{comment.comment}</p>
										</div>
									))}
								</div>
							)}
						</div>
					))}
				</div>
			) : (
				<div className="NoPost">
					<p>You have no posts yet..</p>
				</div>
			)}
		</div>
	);
};

export default PersonalPosts;
