import { Link } from "react-router-dom";
import "./styles/PersonalProfile.css";
import { Outlet } from "react-router-dom";
import { useAuthContext } from "../context/AuthContext";
import { gql, useApolloClient } from "@apollo/client";
import { useEffect, useState } from "react";

const GET_PROFILE_INFO = gql`
	query GetProfilePic($username: String!) {
		user(username: $username) {
			picture
			followerCount
		}
	}
`;

const PersonalProfile = () => {
	const { username } = useAuthContext();
	const [profilePicSrc, setProfilePicSrc] = useState(process.env.REACT_APP_REST_URL + "image/default.jpg");
	const [followerCount, setFollwerCount] = useState(0);
	const apolloClient = useApolloClient();

	useEffect(() => {
		apolloClient
			.query({
				query: GET_PROFILE_INFO,
				variables: { username },
			})
			.then((res) => {
				setProfilePicSrc(process.env.REACT_APP_REST_URL + "image/" + res.data.user.picture);
				setFollwerCount(res.data.user.followerCount);
			});
	}); 

	return (
		<div className="PersonalProfile">
			<div className="PersonalInfo">
				<img
					src={profilePicSrc}
					alt={username}
				></img>
				<p>{username}</p>
				<p id="PersonalFollowerCount">{followerCount}</p>
				<p id="PersonalFollowersText">Followers</p>
			</div>

			<div className="PersonalProfileSections">
				<Link to="">
					<button type="button">Your Live Stream</button>
				</Link>
				<Link to="posts">
					<button type="button">Your Posts</button>
				</Link>
				<Link to="paststreams">
					<button type="button">Your Live and Past Streams</button>
				</Link>
				<Link to="personalFollowing">
					<button type="button">Following</button>
				</Link>
				<Link to="manageAccount">
					<button type="button">Account</button>
				</Link>
			</div>

			<Outlet />
		</div>
	);
};

export default PersonalProfile;
