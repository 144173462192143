import './styles/PersonalAccount.css'
import React, { useState } from 'react';
import { useAuthContext } from "../context/AuthContext";
import { gql, useMutation } from "@apollo/client";
import axios from "axios";
import { useNavigate } from 'react-router-dom';

const CHANGE_PASSWORD = gql`
mutation changePassword(
  $username: String!
  $token: String!
  $old: String!
  $password: String!
) {
  changePassword(
    username: $username
    token: $token
    old: $old
    password: $password
  )
}
`;

const UPDATE_PROFILE_PICTURE = gql`
	mutation UpdateInfo(
		$username: String!
		$token: String!
		$picture: String!
	) {
		updateInfo(
			username: $username
			token: $token
			picture: $picture
		) {
			username
		}
	}
`; 

const PersonalAccount = () => {
    const { username, jwt: token, setJwt, setUsername } = useAuthContext();
    const navigate = useNavigate();

    // CHANGE PROFILE PICTURE
    const [newProfilePicture, setNewProfilePicture] = useState<File>();
    const [uploadPictureError, setUploadPictureError] = useState(false);
    const [uploadPictureSuccess, setUploadPictureSuccess] = useState(false);

    const [updateProfilePicture] = useMutation(UPDATE_PROFILE_PICTURE, {
		onCompleted: (data) => {
            setUploadPictureSuccess(true);
		},
		onError: ({ graphQLErrors, networkError }) => {
            setUploadPictureError(true);

			if (graphQLErrors)
				graphQLErrors.map(({ message, locations, path }) =>
					console.log(
						`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
					)
				);

			if (networkError) console.log(`[Network error]: ${networkError}`);
		},
	}); 

    const handleChangeProfilePicture = () => {
        setUploadPictureError(false);
        setUploadPictureSuccess(false);

        if (!newProfilePicture) return;

        var formData = new FormData();
        formData.append("image", newProfilePicture);

        axios.post(process.env.REACT_APP_REST_URL + "upload/" + username + "/" + token, formData)
            .then(res => {
                updateProfilePicture({variables: {username: username, token: token, picture: res.data.data}});
            })
            .catch(error => {
                setUploadPictureError(true);
            })
    }

    // CHANGE PASSWORD
    const [oldPassword, setOldPassword] = useState("");
    const [newPassword1, setNewPassword1] = useState("");
    const [newPassword2, setNewPassword2] = useState("");
    const [emptyFieldsMsg, setEmptyFieldsMsg] = useState(false);
    const [passwordNotMatchingMsg, setPasswordNotMatchingMsg] = useState(false);
    const [poorPasswordMsg, setPoorPasswordMsg] = useState(false);
    const [successfulPasswordChange, setSuccessfulPasswordChange] = useState(false);
    const [oldPasswordIncorrect, setOldPasswordIncorrect] = useState(false);

    const [changePassword] = useMutation(CHANGE_PASSWORD, {
        variables: {
			username: username,
			token: token,
			old: oldPassword,
			password: newPassword1
		},
        onCompleted: (data) => {
            setOldPassword("");
            setNewPassword1("");
            setNewPassword2("");
            setSuccessfulPasswordChange(true);
        },
        onError: ({ graphQLErrors, networkError }) => {
            if (graphQLErrors)
                setOldPasswordIncorrect(true);
                // graphQLErrors.map(({ message, locations, path }) =>
                //     console.log(
                //         `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
                //     ),
                // );

            if (networkError) console.log(`[Network error]: ${networkError}`);
        }
    });

    const passwordRequirementCheck = () => {
		if (newPassword1.length < 8) return false;
		if (newPassword1.toUpperCase() === newPassword1) return false;
		if (newPassword1.toLowerCase() === newPassword1) return false;
		return true;
	} 

    const handleChangePassword = () => {
        setEmptyFieldsMsg(false);
        setPasswordNotMatchingMsg(false);
        setPoorPasswordMsg(false);
        setSuccessfulPasswordChange(false);
        setOldPasswordIncorrect(false);

        if (oldPassword === "" || newPassword1 === "" || newPassword2 === "") {
            setEmptyFieldsMsg(true);
        } else if (newPassword1 !== newPassword2) {
            setPasswordNotMatchingMsg(true);
        } else if (!passwordRequirementCheck()) {
            setPoorPasswordMsg(true);
        } else {
            changePassword();
        }
    }

    return (
        <div className="PersonalAccount">
            <div className="Signout">
                <h1>Log Out of Krusty Krab?</h1>
                <button
					type="button"
					onClick={(e) => {
						e.preventDefault();
						setUsername("");
						setJwt("");
                        navigate("/");
					}}
				>
					Log Out
				</button>
            </div>

            <div className="ManageProfilePicture">
                <h1>Change your profile picture</h1>
                <div className="PictureUpload">
                    <input 
                        type="file"
                        id="profilePic"
                        className="profilePic"
                        accept=".png, .jpg, .jpeg"
                        onChange={(e) => {
                            // @ts-ignore: Object is possibly 'null'.
                            setNewProfilePicture(e.target.files[0]);
                        }}
                    />
                </div>

                { newProfilePicture && (
                    <div className="CurrentSelectedPicture">
                        <img
					        src={URL.createObjectURL(newProfilePicture)}
					        alt={username}
				        />
                        <div className="ProfilePictureButton">
                            <button type="button" onClick={() => handleChangeProfilePicture()}>
                                Confirm Picture
                            </button>
                        </div>
                    </div>
                )}

                {uploadPictureError && (
                    <div className="UploadPictureError">
                        <p>Something went wrong uploading your new picture..</p>
                    </div>
                )}

                {uploadPictureSuccess && (
                    <div className="UploadPictureSuccess">
                        <p>Your profile picture has been successfully updated!</p>
                    </div>
                )}
            </div>

            <div className="ManagePassword">
                <h1>Change your password</h1>
                <div className="OldPassword">
                    <p>Enter your old password</p>
                    <input
                        placeholder="Enter your old password"
                        name="password_input"
                        id="OldPasswordInput"
                        type="password"
                        value={oldPassword}
                        onChange={(e) => setOldPassword(e.target.value)}
                    />
                </div>

                <div className="NewPassword">
                    <p>Create your new password</p>
                    <input
                        placeholder="Enter your new password"
                        name="password_input"
                        id="NewPasswordInput1"
                        type="password"
                        value={newPassword1}
                        onChange={(e) => setNewPassword1(e.target.value)}
                    />

                    <p>Enter your new password again</p>
                    <input
                        placeholder="Re-enter your new password"
                        name="password_input"
                        id="NewPasswordInput2"
                        type="password"
                        value={newPassword2}
                        onChange={(e) => setNewPassword2(e.target.value)}
                    />
                    <p id="NewPasswordRequirements">Password Requirements<br></br>**must be at least 8 characters long<br></br>**must contain at least 1 upper and 1 lowercase letter</p>
                </div>

                { emptyFieldsMsg && (
                    <div className="EmptyFieldMsg">
                        <p>Some fields are empty..</p>
                    </div>
                )}

                { passwordNotMatchingMsg && (
                    <div className="PasswordNotMatching">
                        <p>Your new passwords don't match.</p>
                    </div>
                )}

                { poorPasswordMsg && (
                    <div className="PoorPasswordMsg">
                        <p>Your new password doesn't meet the minimum requirements...</p>
                    </div>
                )}

                { oldPasswordIncorrect && (
                    <div className="OldPasswordIncorrectMsg">
                        <p>The old password your entered is incorrect...</p>
                    </div>
                )}

                { successfulPasswordChange && (
                    <div className="SuccessfulPasswordChange">
                        <p>Your password was changed successfully!</p>
                    </div>
                )}          

                <div className="ChangePasswordButton">
                    <button type="button" onClick={() => handleChangePassword()}>
						Confirm Password Change
					</button>
                </div>
            </div>
        </div>
    );
}

export default PersonalAccount;