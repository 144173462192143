import './styles/PersonalFollowing.css';
import { gql, useQuery, useMutation, useApolloClient } from "@apollo/client";
import React, { useState } from 'react';
import { useAuthContext } from "../context/AuthContext";
import { Link } from "react-router-dom";

const FIND_FOLLOWERS = gql`
    query FindFollowers ($username: String!) {
        user(username: $username) {
            following
        }
    }  
`
;

const GET_PROFILE_PICTURES = gql`
    query UserPictures ($users: [String]!) {
        userPictures (users: $users)
    }
`
;

const FOLLOW_USER = gql`
mutation followUser(
  $username: String!
  $token: String!
  $following: String!
  $action: Boolean!
) {
  followUser(
    username: $username
    token: $token
    following: $following
    action: $action
  )
}
`;

const PersonalFollowing = () => {
    const { username, jwt: token } = useAuthContext();
    const apolloClient = useApolloClient();
    const [followingList, setFollowingList] = useState<Array<string>>([]);
    const [followingListPics, setFollowingListPics] = useState<Array<string>>([]);
    const [isFollowingList, setIsFollowingList] = useState<Array<boolean>>([]);

    const {error: findFollwersError} = useQuery(FIND_FOLLOWERS, {
        variables: { username: username },
        onCompleted: findFollwersData => {
            let followersList = findFollwersData.user.following;
            setFollowingList(followersList);

            let isFollowingListTemp = new Array<boolean>(followersList.length);
            isFollowingListTemp.fill(true);
            setIsFollowingList(isFollowingListTemp);
            setFollowingListPics(new Array(findFollwersData.user.following.length).fill("default.jpg"));
            let users = findFollwersData.user.following;

            apolloClient
			      .query({
				        query: GET_PROFILE_PICTURES,
				        variables: { users },
			      })
			      .then((res) => {
				        setFollowingListPics(res.data.userPictures);
			      });
        }
    });

    if (findFollwersError) {}

    const [followUser] = useMutation(FOLLOW_USER, {
        onCompleted: (data) => {
            //console.log(data)
        },
        onError: ({ graphQLErrors, networkError }) => {
            if (graphQLErrors)
                graphQLErrors.map(({ message, locations, path }) =>
                    console.log(
                        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
                    ),
                );

            if (networkError) console.log(`[Network error]: ${networkError}`);
        }
    });

    const HandleFollowUser = (follow_user: string, index: number ) => {
        followUser({ variables: {username: username, token: token, following: follow_user, action: !isFollowingList[index]} });
        let isFollowingListTemp = isFollowingList;
        isFollowingListTemp[index] = !isFollowingList[index];
        console.log(isFollowingListTemp);
        setIsFollowingList(isFollowingListTemp);
    }

    return (
        <div className="PersonalFollowing">
            {followingList.length > 0
                ? <div className="FollowingList">
                    <p>You're following</p>
                    {followingList.map((following, index) => (
                        <div className="FollowingUser" key={index}>
                            <div className="AlignImageAndFollowing">
                                <img
                                    src={process.env.REACT_APP_REST_URL + "image/" + followingListPics[index]}
                                    alt={following}
                                />
                                <span><Link style={{ textDecoration: 'none', color: 'white' }} to={"/profile/" + following}>{following}</Link></span>

                                {isFollowingList[index] === true
                                    ? <button type="button" onClick={() => HandleFollowUser(following, index)}>Unfollow</button>
                                    : <button type="button" onClick={() => HandleFollowUser(following, index)}>Follow</button>
                                }
                            </div>
                        </div>
                        
                    ))}

                </div>
                : <div className="EmptyFollowingList">
                    <p>You aren't following anyone!</p>
                </div>
            }
        </div>
    );
}

export default PersonalFollowing;