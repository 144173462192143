import "./styles/PersonalLiveStream.css";
import { useRef, useState } from "react";
import { gql, useApolloClient } from "@apollo/client";
import { useAuthContext } from "../context/AuthContext";
import { io, Socket } from "socket.io-client";
import * as mediasoupClient from "mediasoup-client";

type ChatMessage = {
	clientId: string;
	message: string;
	from: string;
	date: Date;
};

const PersonalLiveStream = () => {
	const MEDIA_SERVER_URL =
		process.env.REACT_APP_MEDIA_SERVER_URL || "localhost:4002";
	const videoRef = useRef<HTMLVideoElement>(null);
	const [currentRoomId, setCurrentRoomId] = useState("");
	const [currentSocket, setCurrentSocket] = useState<Socket>();
	const [streamStatus, setStreamStatus] = useState<0 | 1 | 2 | 3 | 4>(0);
	const { username, jwt } = useAuthContext();
	const [title, setTitle] = useState("");
	const [chatMessages, setChatMessages] = useState<ChatMessage[]>([]);
	const [message, setMessage] = useState("");
	const [clientId, setClientId] = useState("");
	const [currentStream, setCurrentStream] = useState<MediaStream>();

	const consoleLog = (...args: any) => {
		console.log(...args);
	};

	const apolloClient = useApolloClient();

	const CREATE_LIVESTREAM = gql`
		mutation CreateNewLivestream(
			$username: String!
			$token: String!
			$livestreamToken: String!
			$title: String!
		) {
			createLivestream(
				username: $username
				token: $token
				livestreamToken: $livestreamToken
				title: $title
			)
		}
	`;

	const STOP_LIVESTREAM = gql`
		mutation StopLivestream(
			$username: String!
			$token: String!
			$livestreamToken: String!
		) {
			stopLivestream(
				username: $username
				token: $token
				livestreamToken: $livestreamToken
			)
		}
	`;

	const broadcast = async () => {
		let stream: MediaStream | undefined = undefined;

		try {
			stream = await navigator.mediaDevices.getDisplayMedia({
				video: {
					frameRate: { ideal: 60, max: 60 },
					autoGainControl: true,
				},
				audio: true,
			});
		} catch (err) {
			console.error(err);
		}

		if (!stream) {
			try {
				stream = await navigator.mediaDevices.getUserMedia({
					video: {
						frameRate: { ideal: 60, max: 60 },
						autoGainControl: true,
					},
					audio: true,
				});
			} catch (err) {
				console.error(err);
			}
		}

		if (stream === undefined) {
			setStreamStatus(4);
			return;
		}

		setCurrentStream(stream);

		let roomCreated = false;
		consoleLog("Connecting...");
		setStreamStatus(1);

		const socket = io("wss://" + MEDIA_SERVER_URL);
		setCurrentSocket(socket);

		const roomId = crypto.getRandomValues(new Uint8Array(8)).join("");
		setCurrentRoomId(roomId);
		console.log("Room ID:", roomId);

		socket.on(
			"receive_message",
			({ clientId, message, timestamp, from }: any) => {
				consoleLog(`[${clientId}] ${message}`);
				setChatMessages((messages) => [
					{
						clientId,
						message,
						date: new Date(timestamp),
						from,
					},
					...messages,
				]);
			}
		);

		socket.on("connect", () => {
			consoleLog("Connected!");
			setStreamStatus(2);

			socket.emit("create_room", { roomId }, async (err: any, room: any) => {
				if (err) {
					console.error(err);
					return;
				}

				// Edge case, need to fix
				if (roomCreated) {
					console.error("Room already created!");
					return;
				}

				// Create livestream with the room ID
				await apolloClient.mutate({
					mutation: CREATE_LIVESTREAM,
					variables: {
						username,
						token: jwt,
						livestreamToken: roomId,
						title,
					},
				});

				roomCreated = true;
				consoleLog("Room created:", room);

				const device = new mediasoupClient.Device();

				await device.load({
					routerRtpCapabilities: room.capabilities,
				});

				// Join the room.
				socket.emit(
					"join_room",
					{ roomId, username },
					(err: any, clientId: any) => {
						if (err) {
							console.error(err);
							return;
						}

						consoleLog("Joined room:", clientId);
						setClientId(clientId);

						// Get WebRTC transport for sending audio+video from the server.
						socket.emit(
							"create_transport",
							{ roomId, clientId },
							(err: any, transportParams: any) => {
								if (err || !stream) {
									console.error(err);
									return;
								}

								const {
									id,
									iceParameters,
									iceCandidates,
									dtlsParameters,
									sctpParameters,
								} = transportParams;

								consoleLog("Transport created:", transportParams);
								// Create send transport.
								const send_transport = device.createSendTransport({
									id,
									iceParameters: iceParameters,
									iceCandidates: iceCandidates,
									dtlsParameters: dtlsParameters,
									sctpParameters: sctpParameters,
									iceServers: [
										{
											urls: "stun:stun.l.google.com:19302",
										},
										{
											urls: "stun:stun1.l.google.com:19302",
										},
									],
								});

								// Connect the WebRTC transport to the server.

								send_transport.on(
									"connect",
									({ dtlsParameters }, callback, errback) => {
										// Send SCTP parameters to the server.
										socket.emit(
											"connect_transport",
											{
												roomId,
												clientId,
												dtlsParameters,
												transportId: id,
											},
											(err: any) => {
												if (err) {
													console.error(err);
													errback(err);
													return;
												}
												consoleLog("Transport connected");
												callback();
											}
										);
									}
								);

								send_transport.on(
									"produce",
									({ kind, rtpParameters, appData }, callback, errback) => {
										consoleLog(
											"Prepared to produce:",
											kind,
											rtpParameters,
											appData
										);
										// Send RTP parameters and appData to the server.
										socket.emit(
											"produce",
											{
												roomId,
												transportId: id,
												clientId,
												kind,
												rtpParameters,
												appData,
												hasAudio: stream && stream.getAudioTracks().length > 0,
												type: kind,
											},
											(err: any, producerId: any) => {
												if (err) {
													console.error(err);
													errback(err);
													return;
												}

												consoleLog("Producer %s started.", producerId);
												callback({ id: producerId });
											}
										);
									}
								);

								if (videoRef !== null && videoRef.current !== null) {
									videoRef.current.srcObject = stream;
									videoRef.current.play();
								}

								send_transport
									.produce({
										track: stream.getVideoTracks()[0],
									})
									.then(async (producer) => {
										consoleLog("Video producer created:", producer);
									});

								if (stream.getAudioTracks().length > 0) {
									send_transport
										.produce({
											track: stream.getAudioTracks()[0],
										})
										.then(async (producer) => {
											consoleLog("Producer created:", producer);
										});
								}
							}
						);
					}
				);
			});
		});

		socket.connect();
	};

	const stopLivestream = async () => {
		if (currentRoomId !== undefined && currentSocket !== undefined) {
			currentSocket.disconnect();
			setCurrentSocket(undefined);
			setCurrentRoomId("");
		}

		await apolloClient.mutate({
			mutation: STOP_LIVESTREAM,
			variables: {
				username,
				token: jwt,
				livestreamToken: currentRoomId,
			},
		});

		if (videoRef !== null && videoRef.current !== null) {
			videoRef.current.srcObject = null;
			videoRef.current.pause();
		}

		currentStream?.getTracks().forEach((track) => track.stop());

		setStreamStatus(3);
	};

	console.log("Re-render")
	console.log(streamStatus);

	return (
		<div className="PersonalLiveStream">
			<div className="PersonalLiveStream__container">
				<div className="PersonalLiveStream__container__video">
					<video
						className="PersonalLiveStream__container__video__video"
						autoPlay
						muted
						loop
						controls
						ref={videoRef}
					/>
				</div>
				<div className="PersonalLiveStream__container__actions">
					<h3 className="PersonalLiveStream__container__description__header">
						Actions
					</h3>

					{streamStatus === 0 && (
						<button
							className="PersonalLiveStream__container__actions__button"
							onClick={broadcast}
						>
							Broadcast
						</button>
					)}
					{streamStatus === 1 && (
						<button className="PersonalLiveStream__container__actions__button">
							Connecting...
						</button>
					)}
					{streamStatus === 2 && (
						<button
							className="PersonalLiveStream__container__actions__stopbutton "
							onClick={stopLivestream}
						>
							Stop stream
						</button>
					)}
					{streamStatus === 3 && (
						<p
							style={{
								textAlign: "center",
								width: "100%",
								cursor: "pointer",
							}}
							onClick={() => {
								console.log("Restart stream");
								setStreamStatus(0);
								setTitle("");
								setChatMessages([]);
								setCurrentStream(undefined);
							}}
						>
							Stream ended. Click this message to prepare a new one.
						</p>
					)}
					{streamStatus === 4 && (
						<p
							style={{
								textAlign: "center",
								width: "100%",
								cursor: "pointer",
							}}
							onClick={() => {
								setStreamStatus(0);
							}}
						>
							Could not obtain permission to access screen capture nor camera
							and microphone. Click this message to try again.
						</p>
					)}
				</div>
				<div className="PersonalLiveStream__container__description">
					<h3 className="PersonalLiveStream__container__description__header">
						Options
					</h3>
					<p className="PersonalLiveStream__container__description__subheader">
						Stream title
					</p>
					<textarea
						className="PersonalLiveStream__container__actions__textarea"
						placeholder="Livestream title"
						onChange={(e) => setTitle(e.target.value)}
						readOnly={streamStatus !== 0}
					></textarea>
				</div>
				<div className="PersonalLiveStream__container__description">
					<h3 className="PersonalLiveStream__container__description__header">
						Stream chat
					</h3>
					<p className="PersonalLiveStream__container__description__subheader">
						Message
					</p>
					<textarea
						className="PersonalLiveStream__container__actions__textarea"
						placeholder="Type your message here!"
						readOnly={streamStatus !== 2}
						onChange={(e) => setMessage(e.target.value)}
						value={message}
						onKeyDown={(e) => {
							if (e.key === "Enter") {
								e.preventDefault();
								if (currentSocket) {
									currentSocket.emit("send_message", {
										roomId: currentRoomId,
										clientId: clientId,
										message: message,
									});
								}
								setMessage("");
							}
						}}
					/>
				</div>
				{chatMessages.length > 0 &&
					chatMessages.map((message) => {
						const clientName =
							message.clientId === clientId ? "You" : message.from;
						return (
							<div className="PersonalLiveStream__container__description">
								<p>
									{message.date.toLocaleTimeString()} &nbsp;&nbsp; {clientName}{" "}
									said: {message.message}
								</p>
							</div>
						);
					})}
			</div>
		</div>
	);
};

export default PersonalLiveStream;
