import { useContext, createContext } from "react";

// Authentication context containing:
// jwt: the jwt token
// username: the username of the user
// setJwt: a function to set the jwt token
// setUsername: a function to set the username

const AuthContext = createContext({
    jwt: "",
    username: "",
    setJwt: (jwt: string) => {},
    setUsername: (username: string) => {},
});

const useAuthContext = () => useContext(AuthContext);

export { AuthContext, useAuthContext };
