import "./styles/CreditsPage.css";

const CreditsPage = () => {
	return (
		<div className="CreditsPage">
		    <div className="CodeCredits">
			<h2>Code Credits</h2>
			<p>Notifications implemented with socket.io from Youtuber, <a href="https://www.youtube.com/watch?v=7vVqMR96T5o&t=3326s">Lama Dev</a></p>
			<p>Making Graphql Queries and Mutations in React from <a href="https://www.apollographql.com/docs/react">Apollo Docs</a></p>
			<p>Loading animation css taken from <a href="https://loading.io/css/">Loading.IO</a></p>
			<p>Using Graphql and React Router to create login/signup page learned from <a href="https://www.howtographql.com/react-apollo/5-authentication/">How To Graphql</a></p>
			<p>GraphQL schema adapted in backend from <a href="https://www.freecodecamp.org/news/how-to-build-a-blazing-fast-graphql-api-with-node-js-mongodb-and-fastify-77fd5acd2998/">freeCodeCamp</a> </p>
			<p>User picture aggregation function in backend from <a href="https://stackoverflow.com/questions/22797768/does-mongodbs-in-clause-guarantee-order">Stackoverflow 1</a> and <a href="https://stackoverflow.com/questions/71081229/how-to-return-only-the-value-contained-in-the-field-without-the-key-with-mongoos">Stackoverflow 2</a> </p>
			<p>Typical Mediasoup workflow diagram from <a href="https://www.youtube.com/watch?v=DOe7GkQgwPo">https://www.youtube.com/watch?v=DOe7GkQgwPo</a></p>
			<p>FFMpeg recording through Mediasoup from <a href="https://github.com/Kurento/mediasoup-demos/tree/master/mediasoup-recording">https://github.com/Kurento/mediasoup-demos/tree/master/mediasoup-recording</a></p>
		    </div>
		    <div className="MediaCredits">
			<h2>Media Credits</h2>
			<p>Default Profile Picture taken from <a href="https://pixabay.com/vectors/blank-profile-picture-mystery-man-973460/">Pixabay</a></p>
			<p>Crab icon used as our logo taken from <a href="https://www.flaticon.com/premium-icon/crab_166716?term=crab&page=1&position=11&page=1&position=11&related_id=166716&origin=tag">Flaticon</a></p>
		    </div>
			
		</div>
	);
};

export default CreditsPage;
