import React, { useState, useEffect } from "react";
import { Routes, Route, Link, useNavigate } from "react-router-dom";
import "./styles/Homepage.css";
import Profile from "./Profile";
import Posts from "./Posts";
import PastLiveStreams from "./PastLiveStreams";
import PersonalProfile from "./PersonalProfile";
import PersonalLiveStream from "./PersonalLiveStream";
import PersonalPosts from "./PersonalPosts";
import PersonalPastLiveStreams from "./PersonalPastLiveStreams";
import PersonalFollowing from "./PersonalFollowing";
import Home from "./Home";
import WatchStream from "./WatchStream";
import PersonalAccount from "./PersonalAccount";
import ProfileSearchResults from "./ProfileSearchResults";
import CreditsPage from "./CreditsPage";
import { gql, useApolloClient } from "@apollo/client";
import { io } from "socket.io-client";

interface HomepageProps {
	username: string;
}

const GET_PROFILE_PIC = gql`
	query GetProfilePic($username: String!) {
		user(username: $username) {
			picture
		}
	}
`;

type notificationData = {
	senderName: string;
	message: string;
	date: string;
};

const Homepage: React.FC<HomepageProps> = (props) => {
	const [searchTerm, setSearchTerm] = useState("");
	const username = props.username;
	const socket = io(
		process.env.REACT_APP_NOTIFICATION_SOCKET || "http://localhost:5000"
	);
	const [profilePicSrc, setProfilePicSrc] = useState(
		process.env.REACT_APP_REST_URL + "image/default.jpg"
	);
	const [notifications, setNotifications] = useState<notificationData[]>([]);
	const [showNotifications, setShowNotifications] = useState(false);

	const navigate = useNavigate();

	const apolloClient = useApolloClient();

	useEffect(() => {
		socket.emit("newUser", username);
	}, [socket, username]);

	useEffect(() => {
		socket.on("getNotification", (data) => {
			setNotifications((prev) => [data, ...prev]);
		});
	}, [socket]);

	useEffect(() => {
		apolloClient
			.query({
				query: GET_PROFILE_PIC,
				variables: { username },
			})
			.then((res) => {
				setProfilePicSrc(
					process.env.REACT_APP_REST_URL + "image/" + res.data.user.picture
				);
			});
	});

	const logo = require("../images/krustykrab.png");
	const bell = require("../images/bell.png");

	const Main = () => (
		<Routes>
			<Route path="/" element={<Home />} />
			<Route path="/profile/:streamer" element={<Profile socket={socket} />}>
				<Route path="" element={<PastLiveStreams />} />
				<Route path="posts" element={<Posts socket={socket} />} />
			</Route>
			<Route path="/search/:profile" element={<ProfileSearchResults />} />
			<Route path="/me" element={<PersonalProfile />}>
				<Route path="" element={<PersonalLiveStream />} />
				<Route path="posts" element={<PersonalPosts />} />
				<Route path="paststreams" element={<PersonalPastLiveStreams />} />
				<Route path="personalFollowing" element={<PersonalFollowing />} />
				<Route path="manageAccount" element={<PersonalAccount />} />
			</Route>
			<Route path="/watch/:id" element={<WatchStream />} />
			<Route path="/credits" element={<CreditsPage />} />
		</Routes>
	);

	return (
		<div className="App">
			<div className="HomepageHeader">
				<div className="Title">
					<Link style={{ textDecoration: "none", color: "white" }} to="/">
						<div className="AlignTitle">
							<img src={logo} alt="Krusty Krab Streams"></img>
							<span>Krusty Krab Streams</span>
						</div>
					</Link>
				</div>

				<div className="SearchBar">
					<input
						placeholder="Find someone to watch"
						name="username_input"
						id="username_input"
						value={searchTerm}
						onChange={(e) => setSearchTerm(e.target.value)}
						onKeyPress={(event) => {
							if (event.key === "Enter") {
								event.preventDefault();
								navigate("/search/" + searchTerm);
							}
						}}
					/>
				</div>

				<div className="AccountInfoWrap">
					<div className="AccountInfo">
						<div className="Notifications">
							<img
								src={bell}
								alt="Notifications"
								onClick={() => setShowNotifications(!showNotifications)}
							/>
							{notifications.length > 0 && (
								<div className="NotificationsCounter">
									{notifications.length}
								</div>
							)}
						</div>

						<div className="Account">
							<Link style={{ textDecoration: "none", color: "white" }} to="/me">
								<div className="Align">
									<img src={profilePicSrc} alt="Profile"></img>
									<span>{username}</span>
								</div>
							</Link>
						</div>
					</div>
				</div>
			</div>

			{showNotifications && (
				<div className="NotificationList">
					<h1>Notifications</h1>

					{notifications.length > 0 ? (
						<div className="NotificationsExist">
							{notifications.map((notifMsg, index) => (
								<div className="NotificationMessage" key={index}>
									<p>{notifMsg.message}</p>
									<p id="NotificationDate">{notifMsg.date}</p>
								</div>
							))}
						</div>
					) : (
						<p>You have no notifications.</p>
					)}
				</div>
			)}
			<Main />
		</div>
	);
};

export default Homepage;
