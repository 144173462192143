import { gql, useApolloClient } from "@apollo/client";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useAuthContext } from "../context/AuthContext";
import "./styles/PastLiveStreams.css";

type Livestream = {
	title: string;
	username: string;
	date: string;
	livestreamToken: string;
};

const PastLiveStreams = () => {
	const apolloClient = useApolloClient();
	const { username, jwt } = useAuthContext();
	const [livestreams, setLivestreams] = useState<Livestream[]>([]);
	const { streamer } = useParams();

	const GET_LIVE_STREAMS = gql`
		query GetLiveStream(
			$token: String!
			$username: String!
			$streamer: String!
		) {
			livestreamsByStreamer(
				token: $token
				username: $username
				streamer: $streamer
			) {
				title
				username
				date
				livestreamToken
			}
		}
	`;

	const navigate = useNavigate();
	const location = useLocation();

	useEffect(() => {
		// get the stream title and streamer
		apolloClient
			.query({
				query: GET_LIVE_STREAMS,
				variables: {
					token: jwt,
					username: username,
					streamer: streamer,
				},
			})
			.then((res) => {
				const livestream_response = (res.data.livestreamsByStreamer ||
					[]) as Livestream[];

				const sorted_livestreams = [] as Livestream[];
				Object.assign(sorted_livestreams, livestream_response);

				sorted_livestreams.sort((a, b) => {
					return new Date(b.date).getTime() - new Date(a.date).getTime();
				});

				setLivestreams(sorted_livestreams);
			})
			.catch((err) => {
				console.error(err);
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location]);

	return (
		<div className="PastLiveStreams">
			<div className="PastLiveStreams__container">
				<h1 className="PastLiveStreams__header">Past Live Streams</h1>
				{livestreams.map((livestream) => {
					return (
						<div
							className="PastLiveStreams__card"
							onClick={(e) => {
								e.preventDefault();
								navigate("/watch/" + livestream.livestreamToken);
							}}
							key={livestream.livestreamToken}
						>
							<div className="PastLiveStreams__card__title">
								{livestream.title || "(Untitled)"}
							</div>
							<div className="PastLiveStreams__card__date">
								Began at {new Date(livestream.date).toLocaleString()}
							</div>
						</div>
					);
				})}
				{livestreams.length === 0 && (
					<p>No past live streams found.</p>
				)}
			</div>
		</div>
	);
};

export default PastLiveStreams;
