import { AuthContext } from "../context/AuthContext";
import React, { useEffect, useState } from "react";

// Function with arguments:
// - a React state setter function 
// - name of the property
// and returns a function that takes the new value of the property and sets it in local storage
// and then calls the React setState function with the new value
const setState = (setter: any, name: string) => (value: any) => {
    localStorage.setItem(name, value);
    setter(value);
};

type AuthProviderProps = {
    children: React.ReactNode;
};

const AuthProvider = ({ children }: AuthProviderProps) => {
    const [jwt, setJwtRaw] = useState("");
    const [username, setUsernameRaw] = useState("");

    const setUsername = setState(setUsernameRaw, "username");
    const setJwt = setState(setJwtRaw, "jwt");

    useEffect(() =>{
        const jwt = localStorage.getItem("jwt");
        const username = localStorage.getItem("username");

        if (jwt) {
            setJwt(jwt);
        }

        if (username) {
            setUsername(username);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <AuthContext.Provider
            value={{
                jwt,
                username,
                setJwt,
                setUsername,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
}

export default AuthProvider;