import React, { useState } from "react";
import "./styles/LoginPage.css";
import { gql, useMutation, useApolloClient } from "@apollo/client";
import { useAuthContext } from "../context/AuthContext";

const LoginPage = () => {
    const [displaySignup, setDisplaySignup] = useState(false);
    const [accountCreatedMessage, setAccountCreatedMessage] = useState(false);
    const [accountAlreadyExistsMessage, setAccountAlreadyExistsMessage] =
        useState(false);
    const [poorPasswordMessage, setPoorPasswordMessage] =
        useState(false);
    const [emptyFieldsMessage, setEmptyFieldsMessage] = useState(false);
    const [incorrectLoginMessage, setIncorrectLoginMessage] = useState(false);
    const [forgotPasswordPage, setForgotPasswordPage] = useState(false);
    const [showResetPasswordEmailMessage, setShowResetPasswordEmailMessage] = useState(false);
    const [resetPasswordEmail, setResetPasswordEmail] = useState("");
    const [showLoadingAnimation, setShowLoadingAnimation] = useState(false);
    const [enteredUsername, setEnteredUsername] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const { setJwt, setUsername } = useAuthContext();
    const apolloClient = useApolloClient();

    const saveJWT = (userJWT: string) => {
        sessionStorage.setItem("jwt", userJWT);
        setJwt(userJWT);
    };

    const handleSwitch = () => {
        setDisplaySignup(!displaySignup);
        setAccountCreatedMessage(false);
        setAccountAlreadyExistsMessage(false);
        setEmptyFieldsMessage(false);
        setIncorrectLoginMessage(false);
        setPoorPasswordMessage(false);
        setEnteredUsername("");
        setEmail("");
        setPassword("");
    };

    const CREATE_ACCOUNT = gql`
		mutation CreateUser(
			$username: String!
			$email: String!
			$password: String!
		) {
			createUser(username: $username, email: $email, password: $password) {
				username
			}
		}
	`;

    const SIGN_IN = gql`
		mutation CreateUser($email: String!, $password: String!) {
			userLogin(email: $email, password: $password)
		}
	`;

    const RESET_PASSWORD = gql`
        query ForgotPassword ($email: String!) {
            forgotPassword(email: $email)
        }  
    `;

    const [createAccount] = useMutation(CREATE_ACCOUNT, {
        variables: {
            username: enteredUsername,
            email: email,
            password: password,
        },
        onCompleted: (data) => {
            console.log(data);
            handleSwitch();
            setAccountCreatedMessage(true);
            setShowLoadingAnimation(false);
        },
        onError: ({ graphQLErrors, networkError }) => {
            if (graphQLErrors)
                graphQLErrors.map(({ message, locations, path }) =>
                    console.log(
                        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
                    )
                );

            setAccountAlreadyExistsMessage(true);
            setShowLoadingAnimation(false);

            if (networkError) console.log(`[Network error]: ${networkError}`);
        },
    });

    const [signin] = useMutation(SIGN_IN, {
        variables: {
            email: email,
            password: password,
        },
        onCompleted: (data) => {
            saveJWT(data.userLogin[1]);
            setUsername(data.userLogin[0]);
            setShowLoadingAnimation(false);
        },
        onError: ({ graphQLErrors, networkError }) => {
            if (graphQLErrors)
                graphQLErrors.map(({ message, locations, path }) =>
                    console.log(
                        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
                    )
                );

            setIncorrectLoginMessage(true);
            setShowLoadingAnimation(false);

            if (networkError) console.log(`[Network error]: ${networkError}`);
        },
    });

    const passwordRequirementCheck = () => {
        if (password.length < 8) return false;
        if (password.toUpperCase() === password) return false;
        if (password.toLowerCase() === password) return false;
        return true;
    }

    const handleSignup = () => {
        setEmptyFieldsMessage(false);
        setAccountAlreadyExistsMessage(false);
        setPoorPasswordMessage(false);

        if (enteredUsername.length === 0 || email.length === 0 || password.length === 0) {
            setEmptyFieldsMessage(true);
        } else if (!passwordRequirementCheck()) {
            setPoorPasswordMessage(true);
        } else {
            setShowLoadingAnimation(true);
            createAccount();
        }
    };

    const handleLogin = () => {
        setEmptyFieldsMessage(false);
        setIncorrectLoginMessage(false);

        if (email.length === 0 || password.length === 0) {
            setEmptyFieldsMessage(true);
        } else {
            setShowLoadingAnimation(true);
            signin();
        }
    };

    const resetPassword = () => {
        apolloClient
			.query({
				query: RESET_PASSWORD,
				variables: { email: resetPasswordEmail },
			})
			.then((res) => {
				console.log(res);
			})
            .catch((err) => {
                console.log(err);
            });
    }

    const handleResetPassword = () => {
        setEmptyFieldsMessage(false);
        setShowResetPasswordEmailMessage(false);

        if (resetPasswordEmail.length === 0) {
            setEmptyFieldsMessage(true);
            return;
        }
        setShowResetPasswordEmailMessage(true);
        resetPassword();
    }

    const handleForgetPassword = () => {
        setForgotPasswordPage(true);
    }

    const handleBackToLoginPage = () => {
        setShowResetPasswordEmailMessage(false);
        setEmptyFieldsMessage(false);
        setForgotPasswordPage(false);
    }

    if (forgotPasswordPage) {
        return (
            <div className="ResetPasswordPage">
                <p>Reset your password</p>

                <div className="InputBar">
                    <p>Enter your email</p>
                    <input
                        placeholder="Email"
                        name="email_input"
                        id="email_input"
                        value={resetPasswordEmail}
                        onChange={(e) => setResetPasswordEmail(e.target.value)}
                    />
                </div>

                <div className="ResetPasswordButton">
                    <button type="button" onClick={() => handleResetPassword()}>
                        Reset Password
                    </button>
                </div>

                {emptyFieldsMessage && (
                    <div className="EmptyFieldsMessage">
                        <p>You can't leave the email field empty!</p>
                    </div>
                )}

                {showResetPasswordEmailMessage && (
                    <div className="ResetPasswordEmailMessage">
                        <p>An email has been sent with instructions to reset your password!</p>
                    </div>
                )}

                <div className="BackToLoginPage">
                    <p onClick={() => handleBackToLoginPage()}>
                        back to login page
                    </p>
                </div>
            </div>
        );
    }

    return (
        <div className="LoginPage">
            {displaySignup ? (
                <div className="Signup">
                    <p>Join Krusty Krab Streams</p>

                    <div className="InputBar">
                        <p>Enter a username</p>
                        <input
                            placeholder="Username"
                            name="username_input"
                            id="username_input"
                            value={enteredUsername}
                            onChange={(e) => setEnteredUsername(e.target.value)}
                        />
                    </div>

                    <div className="InputBar">
                        <p>Enter your email</p>
                        <input
                            placeholder="Email"
                            name="email_input"
                            id="email_input"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </div>

                    <div className="InputBar">
                        <p>Make a password</p>
                        <p id="passwordRequirements">*must be at least 8 characters long<br></br>*must contain at least 1 upper and 1 lowercase letter</p>
                        <input
                            placeholder="Password"
                            name="password_input"
                            id="password_input"
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </div>

                    {accountAlreadyExistsMessage && (
                        <div className="AccountAlreadyCreatedMessage">
                            <p>
                                Try again, someone with the same username/email already
                                exists!
                            </p>
                        </div>
                    )}

                    {poorPasswordMessage && (
                        <div className="PoorPasswordMessage">
                            <p>
                                Your password doesn't meet the minimum requirements.
                            </p>
                        </div>
                    )}

                    {emptyFieldsMessage && (
                        <div className="EmptyFieldsMessage">
                            <p>You can't have empty field(s)!</p>
                        </div>
                    )}

                    {showLoadingAnimation && (
                        <div className="LoadingAnimation"><div></div><div></div><div></div><div></div></div>
                    )}

                    <div className="SignupButton">
                        <button type="button" onClick={() => handleSignup()}>
                            Sign Up
                        </button>
                    </div>
                    <div className="ChangeFromSignupToSignin">
                        <p onClick={() => handleSwitch()}>
                            or, login to an existing account
                        </p>
                    </div>
                </div>
            ) : (
                <>
                    <div className="Login">
                        <p>Log Into Krusty Krab Streams</p>

                        {accountCreatedMessage && (
                            <div className="AccountCreatedMessage">
                                <p>Account created, check your email for a verification link!</p>
                            </div>
                        )}

                        <div className="InputBar">
                            <p>Enter your email</p>
                            <input
                                placeholder="Email"
                                name="email_input"
                                id="email_input"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </div>

                        <div className="InputBar">
                            <p>Enter your password</p>
                            <input
                                placeholder="Password"
                                name="password_input"
                                id="password_input"
                                type="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </div>

                        {emptyFieldsMessage && (
                            <div className="EmptyFieldsMessage">
                                <p>You can't have empty field(s)!</p>
                            </div>
                        )}

                        {incorrectLoginMessage && (
                            <div className="IncorrectLoginMessage">
                                <p>Your email/password is incorrect, try again.</p>
                            </div>
                        )}

                        {showLoadingAnimation && (
                            <div className="LoadingAnimation"><div></div><div></div><div></div><div></div></div>
                        )}

                        <div className="SigninButton">
                            <button type="button" onClick={() => handleLogin()}>
                                Log In
                            </button>
                        </div>
                        <div className="ChangeFromSignupToSignin">
                            <p onClick={() => handleSwitch()}>
                                or, create an account
                            </p>
                        </div>

                        <div className="ForgetPassword">
                            <p onClick={() => handleForgetPassword()}>
                                forgot password?
                            </p>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default LoginPage;