import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import "./styles/Profile.css";
import { Outlet } from "react-router-dom";
import { gql, useApolloClient, useQuery, useMutation } from "@apollo/client";
import { useAuthContext } from "../context/AuthContext";
import { Socket } from "socket.io-client";

const GET_PROFILE_INFO = gql`
	query GetProfilePic($username: String!) {
		user(username: $username) {
			picture
			followerCount
		}
	}
`;

const FIND_USER = gql`
	query FindUsers($username: String!) {
		user(username: $username) {
			username
		}
	}
`;
const FIND_FOLLOWERS = gql`
	query FindFollowers($username: String!) {
		user(username: $username) {
			following
		}
	}
`;
const FOLLOW_USER = gql`
	mutation followUser(
		$username: String!
		$token: String!
		$following: String!
		$action: Boolean!
	) {
		followUser(
			username: $username
			token: $token
			following: $following
			action: $action
		)
	}
`;

interface ProfileProps {
	socket: Socket;
}

const Profile: React.FC<ProfileProps> = (props) => {
	const socket = props.socket;
	const { streamer: username } = useParams();
	const { username: loggedInUsername, jwt: jwtToken } = useAuthContext();
	const [isFollowing, setIsFollowing] = useState(false);

	const { error: findUserError } = useQuery(FIND_USER, {
		variables: { username },
	});

	const { error: findFollowersError } = useQuery(FIND_FOLLOWERS, {
		variables: { username: loggedInUsername },
		onCompleted: (findFollwersData) => {
			let followersList = findFollwersData.user.following;
			if (followersList.indexOf(username) > -1) {
				setIsFollowing(true);
			}
		},
	});

	if (findFollowersError) {
	}

	const [profilePicSrc, setProfilePicSrc] = useState(
		process.env.REACT_APP_REST_URL + "image/default.jpg"
	);
	const [userFollowerCount, setUserFollowerCount] = useState(0);
	const apolloClient = useApolloClient();

	useEffect(() => {
		apolloClient
			.query({
				query: GET_PROFILE_INFO,
				variables: { username },
			})
			.then((res) => {
				setProfilePicSrc(
					process.env.REACT_APP_REST_URL + "image/" + res.data.user.picture
				);
				setUserFollowerCount(res.data.user.followerCount);
			});
	});

	const [followUser] = useMutation(FOLLOW_USER, {
		onCompleted: (data) => {
			if (!isFollowing) {
				handleNotification();
			}
		},
		onError: ({ graphQLErrors, networkError }) => {
			if (graphQLErrors)
				graphQLErrors.map(({ message, locations, path }) =>
					// console.log(
					//     `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
					// ),
					console.log(
						`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
					)
				);

			if (networkError) console.log(`[Network error]: ${networkError}`);
		},
	});

	const handleNotification = () => {
		socket.emit("sendNotification", {
			senderName: loggedInUsername,
			receiverName: username,
			message: loggedInUsername + " followed you!",
			date: new Date().toLocaleString(),
		});
	};

	const HandleFollowUser = () => {
		followUser({
			variables: {
				username: loggedInUsername,
				token: jwtToken,
				following: username,
				action: !isFollowing,
			},
		});

		setIsFollowing(!isFollowing);
	};

	if (findUserError) {
		return (
			<div className="ProfileNotFound">
				<p>Can't find a profile with the name, '{username}'...</p>
			</div>
		);
	}

	return (
		<div className="Profile">
			<div className="UserInfo">
				<img src={profilePicSrc} alt="Profile"></img>
				<p>{username}</p>
				<p id="UserFollowerCount">{userFollowerCount}</p>
				<p id="UserFollowersText">Followers</p>

				{isFollowing ? (
					<button type="button" onClick={() => HandleFollowUser()}>
						Unfollow
					</button>
				) : (
					<button type="button" onClick={() => HandleFollowUser()}>
						{" "}
						Follow
					</button>
				)}
			</div>

			<div className="ProfileSections">
				<Link to="">
					<button type="button">Live and past streams</button>
				</Link>
				<Link to="posts">
					<button type="button">Posts</button>
				</Link>
			</div>

			<Outlet />
		</div>
	);
};

export default Profile;
