import './styles/Home.css'
import { useAuthContext } from "../context/AuthContext";
import { gql, useQuery, useApolloClient } from "@apollo/client";
import React, { useState } from 'react';
import { Link } from "react-router-dom";

const FIND_FOLLOWERS = gql`
query FindFollowers ($username: String!) {
    user(username: $username) {
        following
    }
}  
`
;

const FIND_TOP_FOLLOWERS = gql`
query FindTopFollowers {
    topFollowed {
        username
        followerCount
    }
}  
`
;

const GET_PROFILE_PICTURES = gql`
    query UserPictures ($users: [String]!) {
        userPictures (users: $users)
    }
`
;

const Home = () => {
    const { username } = useAuthContext();
    const apolloClient = useApolloClient();
    const [followingList, setFollowingList] = useState<Array<string>>([]);
    const [topFollowedList, setTopFollowedList] = useState<Array<{username: string, followerCount: number}>>([]);
    const [followingListPics, setFollowingListPics] = useState<Array<string>>([]);
    const [topFollowedListPics, setTopFollowedListPics] = useState<Array<string>>([]);
    
    const { error: findFollwersError } = useQuery(FIND_FOLLOWERS, {
        variables: { username: username },
        onCompleted: findFollowersData => {
            setFollowingList(findFollowersData.user.following);
            setFollowingListPics(new Array(findFollowersData.user.following.length).fill("default.jpg"));

            let users = findFollowersData.user.following;

            apolloClient
			.query({
				query: GET_PROFILE_PICTURES,
				variables: { users },
			})
			.then((res) => {
				setFollowingListPics(res.data.userPictures);
			});
        }
    });
    if (findFollwersError) {}

    const { error: findTopFollwersError } = useQuery(FIND_TOP_FOLLOWERS, {
        onCompleted: findTopFollwersData => {
            setTopFollowedList(findTopFollwersData.topFollowed);
            setTopFollowedListPics(new Array(findTopFollwersData.topFollowed.length).fill("default.jpg"));

            let users = [] as string[];
            for (const topFollow of findTopFollwersData.topFollowed) {
                users.push(topFollow.username);
            }

            apolloClient
			.query({
				query: GET_PROFILE_PICTURES,
				variables: { users },
			})
			.then((res) => {
				setTopFollowedListPics(res.data.userPictures);
			});
        }
    });
    if (findTopFollwersError) {}

    return (
        <div className="Home">
            <div className="TopStreamers">
                <h1>Top streamers right now!</h1>

                {topFollowedList.length > 0
                    ? <div className="TopStreamersContainer">
                        {topFollowedList.map((streamer, index) => (
                            <div className="TopStreamer" key={index}>
                                <Link style={{ textDecoration: 'none', color: 'white' }} to={"/profile/" + streamer.username}>
                                    <img
                                        src={process.env.REACT_APP_REST_URL + "image/" + topFollowedListPics[index]}
                                        alt={streamer.username}
                                    ></img>
                                    <p>{streamer.username}</p>
                                    <p id="TopStreamerFollowerCount">{streamer.followerCount} followers</p>
                                </Link>
                            </div>
                        ))}

                    </div>
                    : <div className="NoTopStreamers">
                        <h1>There are no streamers with followers at the moment..</h1>
                    </div>
                }
            </div>

            <div className="HorizontalLine"></div>

            <div className="FollowedStreamers">
                <h1>Profiles you follow!</h1>

                {followingList.length > 0
                ? <div className="FollowingStreamersContainer">
                    {followingList.map((streamerName, index) => (
                        <div className="FollowingStreamer" key={index}>
                            <Link style={{ textDecoration: 'none', color: 'white' }} to={"/profile/" + streamerName}>
                                <img
                                    src={process.env.REACT_APP_REST_URL + "image/" + followingListPics[index]}
                                    alt={streamerName}
                                ></img>
                                <p>{streamerName}</p>
                            </Link>
                        </div> 
                    ))}

                </div>
                : <div className="NotFollowingStreamers">
                    <h1>You aren't following anyone..</h1>
                    <p><i>Follow people and their profiles will show up on your homepage!</i></p>
                </div>
            }
            </div>

            <div className="HorizontalLine"></div>

            <div className="CreditsPage">
                <Link style={{ textDecoration: 'none', color: 'white' }} to={"/credits"}>
                    View Credits Page
                </Link>
            </div>
        </div>
    );
}

export default Home;