import React from "react";
import Homepage from "./components/Homepage";
import "./App.css";
import { useAuthContext } from "./context/AuthContext";
import { Routes, Route, Link } from "react-router-dom";
import LoginPage from "./components/LoginPage";
import EmailVerification from "./components/EmailVerification";
import ForgetPassword from "./components/ForgetPassword";

function App() {
	const { username, jwt } = useAuthContext();	
	const logo = require('./images/krustykrab.png');

	const Main = () => (
        <Routes>
            <Route path="/" element={<LoginPage/>} />
            <Route path="/verify/:verifyToken" element={<EmailVerification />}/>
			<Route path="/passwordRecovery/:forgetPasswordToken" element={<ForgetPassword />}/>
        </Routes> 
    );

	if (!jwt) {
		return (
			<div className="App">
				<div className="Header">
					<Link style={{ textDecoration: 'none', color: 'white' }} to="/">
						<div className="AlignAppHeader">
							<img src={logo} alt='Krusty Krab Streams'></img>
							<span>Krusty Krab Streams</span>
						</div>
					</Link>
				</div>
				<Main/>
			</div>
		);
	}

	return <Homepage username={username} />;
}

export default App;
