import "./styles/EmailVerification.css";
import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { gql, useMutation } from "@apollo/client";

const VERIFY_USER = gql`
mutation VerifyUser(
  $token: String!
) {
  verifyUser(
    token: $token
  )
}
`;

const EmailVerification = () => {
    const { verifyToken } = useParams();
    const [showLoading, setShowLoading] = useState(true);
    const [successfulVerify, setSuccessfulVerify] = useState(false);
    const [expiredToken, setExpiredToken] = useState(false);
    const [alreadyVerified, setAlreadyVerified] = useState(false);
    const [tokenDNE, setTokenDNE] = useState(false);
    const [networkError, setNetworkError] = useState(false);

    const [verifyUser] = useMutation(VERIFY_USER, {
        variables: {
			token: verifyToken,
		},
		onCompleted: (data) => {
            setSuccessfulVerify(true);
            setShowLoading(false);
		},
		onError: ({ graphQLErrors, networkError }) => {
            setShowLoading(false);
			if (graphQLErrors) {
                switch (graphQLErrors[0].message) {
                    case 'jwt malformed': { 
                        setTokenDNE(true); 
                        break; 
                     }
                     case 'Token expired. Resent new verification email.': { 
                        setExpiredToken(true); 
                        break; 
                     }
                     case 'You are already verified.': { 
                        setAlreadyVerified(true); 
                        break; 
                     }
                     default: { 
                        setNetworkError(true);
                        break; 
                     } 
                }
            }

			if (networkError) setNetworkError(true);
		},
	}); 

    useEffect(() => {
        verifyUser();
    }, [verifyUser]);

	return (
        <div className="EmailVerification">

            {showLoading ? (
                <div className="LoadingVerify">
                    <div className="LoadingAnimation"><div></div><div></div><div></div><div></div></div>
                    <p>Please wait as we verify your email!</p>
                </div>
            ) : (
                <div className="VerificationMsg">
                    {successfulVerify && (
                        <div className="SuccessfulVerify">
                            <h1>You have been successfully verified!</h1>
                            <Link to="/"><button type="button">Login to your new account!</button></Link>
                        </div>
                    )}

                    {expiredToken && (
                        <div className="ExpiredToken">
                            <h1>Your token has expired..</h1>
                            <p>We've sent a new verification email!</p>
                        </div>
                    )}

                    {alreadyVerified && (
                        <div className="AlreadyVerified">
                            <h1>You are already verified.</h1>
                            <Link to="/"><button type="button">Login to your account!</button></Link>
                        </div>
                    )}

                    {tokenDNE && (
                        <div className="TokenDNE">
                            <h1>Your token doesn't exist..</h1>
                            <Link to="/"><button type="button">Try logging into an account?</button></Link>
                        </div>
                    )}                    

                    {networkError && (
                        <div className="VerifyNetworkError">
                            <h1>Oops, something went wrong on our end.</h1>
                            <p>Try again later..</p>
                        </div>
                    )}
                </div>
            )}
        </div>
	);
};

export default EmailVerification;
